<template>
    <div class="container__tag">
        <button type="button" class="btn btn-success btn-sm" style="border-radius: 30px; white-space: nowrap" v-b-modal.modal-company>
            法人名・法人番号から検索して入力
        </button>

        <b-modal
            no-close-on-backdrop
            id="modal-company"
            :title="objectDetail ? '法人情報詳細' : '法人名・法人番号から検索して入力'"
            size="lg"
            title-class="font-18"
            scrollable
            hide-footer
        >
            <form class="needs-validation" autocomplete="off">
                <div v-if="!objectDetail" class="row">
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-header">
                                <h6 class="mb-0">法人検索</h6>
                            </div>
                            <div class="card-body">
                                <div class="d-flex align-items-start" style="white-space: nowrap">
                                    <div class="w-100 mr-1">
                                        <input type="text" v-model="search.name" class="form-control mb-1" placeholder="法人名を入力	" />
                                        <select name="" v-model="search.prefecture" id="" class="mx-0 form-control w-50">
                                            <option value="">所在地</option>
                                            <option :value="item.code" :key="'p' + item.code" v-for="item in listDataMasterPrefecture">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <button type="button" v-if="search.name" @click="findComanies('name')" class="btn btn-primary">検索</button>
                                    <button type="button" v-else class="btn btn-secondary">検索</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-header">
                                <h6 class="mb-0">法人番号検索</h6>
                            </div>
                            <div class="card-body">
                                <div class="d-flex" style="white-space: nowrap">
                                    <input type="text" maxlength="13" v-model="search.number" class="form-control" placeholder="半角数字13行	" />
                                    <button
                                        type="button"
                                        v-if="search.number && search.number.length == 13"
                                        @click="findComanies('number')"
                                        class="btn btn-primary ml-1"
                                    >
                                        検索
                                    </button>
                                    <button type="button" v-else class="btn btn-secondary ml-1">検索</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <div class="card mb-0">
                            <div class="card-header">
                                <h5>検索結果 ({{ this.totalSearch }})</h5>
                            </div>
                            <div class="card-body">
                                <p v-if="check" class="text-center">法人番号・法人名を入力して検索してください。</p>
                                <table class="no-border table mb-0" v-if="listData.length > 0 && !isLoading">
                                    <tbody>
                                        <tr v-for="(item, k) in listData" :key="'cc' + k">
                                            <td>
                                                <h5>
                                                    <b>{{ item.name }}</b>
                                                </h5>
                                                {{ item.prefecture ? item.prefecture.name : '' }}{{ item.city ? item.city.name : '' }}
                                                <span class="font-weight-bold ml-3">法人番号：</span>{{ item.corporate_number }}
                                            </td>
                                            <td align="center">
                                                <a href="javascript:void(0)" @click="viewDetail(item)">
                                                    <i class="fa fa-arrow-right font-size-24"></i><br />
                                                    詳細を見る
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-else-if="isLoading">
                                    <div class="d-flex justify-content-center">
                                        <LoadingIcon />
                                    </div>
                                </div>
                                <p class="text-center" v-if="!check && !isLoading && listData.length == 0">
                                    見つかりませんでした、条件を変えて検索してください。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <table v-else class="table no-border">
                    <tbody>
                        <tr>
                            <th>企業・団体名</th>
                            <td>{{ objectDetail.name }}</td>
                        </tr>
                        <tr>
                            <th>法人番号</th>
                            <td>{{ objectDetail.corporate_number }}</td>
                        </tr>
                        <tr>
                            <th>所在地</th>
                            <td>
                                〒{{ formattedPostalCode(objectDetail.post_code) }}<br />{{
                                    objectDetail.prefecture ? objectDetail.prefecture.name : ''
                                }}{{ objectDetail.city ? objectDetail.city.name : '' }}{{ objectDetail.street_number }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="javascript:void(0)" @click="viewList()">
                                    <i class="fa fa-arrow-left font-size-24"></i><br />
                                    検索に戻る
                                </a>
                            </td>
                            <td align="">
                                <button type="button" class="btn btn-success" style="border-radius: 30px" @click="loadData()">
                                    このデータを入力画面に挿入する
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </b-modal>
    </div>
</template>

<script>
import { formattedPostalCode, masterMethods } from '@/state/helpers';
import LoadingIcon from '@/components/Loading/main.vue';

export default {
    name: 'company',
    components: {
        LoadingIcon
    },
    data() {
        return {
            listDataMasterPrefecture: [],
            check: true,
            search: {
                method: '',
                name: '',
                number: '',
                prefecture: ''
            },
            listData: [],
            objectDetail: null,
            totalSearch: 0,
            isLoading: false
        };
    },
    mounted() {
        this.getListMasterPrefecture();
    },
    watch: {},
    methods: {
        formattedPostalCode,
        ...masterMethods,
        findComanies(method) {
            this.isLoading = true;
            this.check = false;
            this.search.method = method;
            let query = [
                `method=${this.search.method}`,
                `name=${this.search.name}`,
                `number=${this.search.number}`,
                `prefecture=${this.search.prefecture}`
            ];
            this.findCompany(query.join('&'))
                .then((data) => {
                    this.isLoading = false;
                    if (data.code == 200) {
                        this.listData = data.data;
                        this.totalSearch = data._metadata.total;
                    }
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
        getListMasterPrefecture() {
            this.listMaster('prefectures').then((data) => {
                this.listDataMasterPrefecture = data;
            });
        },
        viewDetail(item) {
            this.objectDetail = item;
        },
        viewList() {
            this.objectDetail = null;
        },
        loadData() {
            this.$emit('data', this.objectDetail);
            this.objectDetail = null;
            this.listData = [];
            this.$bvModal.hide('modal-company');
        }
    }
};
</script>
<style lang="scss" scoped>
td,
th {
    border-bottom: 1px solid #eff2f7;
    border-top: 0;
}
</style>
